import React from 'react';
import DOMPurify from 'isomorphic-dompurify';

interface SafeHTMLProps {
  html: string;
  className?: string;
}

/**
 * SafeHTML Component
 *
 * A React component that safely renders HTML content using isomorphic-dompurify
 * to prevent XSS attacks when using dangerouslySetInnerHTML.
 * Works in both server and client environments.
 *
 * @param {string} html - The HTML content to sanitize and render
 * @param {string} className - Optional CSS class name to apply to the wrapper div
 */
const SafeHTML: React.FC<SafeHTMLProps> = ({ html, className = '' }) => {
  // Sanitize the HTML with isomorphic-dompurify
  const sanitizedHTML = html ? DOMPurify.sanitize(html) : '';

  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} className={className} />
  );
};

export default SafeHTML;
