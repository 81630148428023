import React from 'react';
import Image from 'next/image';

type MasonryGalleryProps = {
  imgData: string[];
  imgAlt: string;
};

const MasonryGallery = (props: MasonryGalleryProps): React.JSX.Element => {
  const { imgData, imgAlt } = props;
  return (
    <div className="grid gap-4 lg:grid-cols-2">
      {imgData.map((img, index) => (
        <figure key={index}>
          <Image src={img} alt={imgAlt} width={453} height={380} className="w-full" />
        </figure>
      ))}
    </div>
  );
};

export default MasonryGallery;
